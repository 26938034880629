import axios from 'axios'
const RECAPTCHA_SITE_KEY = '6LdDmKIZAAAAADEmRinEvnHHT8aFboVDe0qOVQbw'
export default {
  setup(callback) {
    return grecaptcha.render('footer-recaptcha', {
      'sitekey' : RECAPTCHA_SITE_KEY,
      'callback' : callback,
      'size' : 'invisible',
      'action': 'footer_form_submit'
      // 'badge' : 'inline'
    });
  },
  reset(recaptcha) {
    grecaptcha.reset(recaptcha);
  },
  execute(recaptcha) {
    grecaptcha.execute(recaptcha);
  },
  verifyToken(token) {
    console.log(`in grecaptcha callback`)
    return axios({
      method:   'get',
      dataType: 'json',
      url:      '/api/verify_captcha.json',
      params: {
        api_token: 'swimmingPandasInaPineTree',
        gtoken: token
      }
    })
  },
  isValid(response) {
    return !!(response.success &&
              response.action === 'footer_form_submit' &&
              response.score >= 0.5);
  }
}