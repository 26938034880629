<template>
    <div class="footer-loading-spinner">
      <div id="footer-email__loadingSpinner" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="loading-text">Loading...</p>
    </div>
</template>

<script>
  export default {

  }
</script>

<style scoped>
    /* loading spinner */
    .footer-loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }


    @media (min-width: 769px) {
      .footer-loading-spinner {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
      }
    }

    #footer .footer-form-disclaimer .footer-loading-spinner .loading-text {
      width: auto;
      margin: 0;
    }

    .lds-ring-cover {
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        position: relative;
        top: 0;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 100px;
        height: 60px;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>