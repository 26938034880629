<template>
  <section class="email-signup-footer">
    <h2 class="category">Get the latest conservation news</h2>
    <form @submit.prevent="submit">
      <div class="footer-form-group" :class="{ 'form-group--error': $v.firstName.$error }">
        <label for="footer-first-name" v-html="firstNameWithError"></label>
        <input autocomplete="given-name" id="footer-first-name" :aria-describedby="firstNameWithErrorId" v-model.trim="$v.firstName.$model" />
      </div>
      <div class="footer-form-group" :class="{ 'form-group--error': $v.lastName.$error }">
        <label for="footer-last-name" v-html="lastNameWithError"></label>
        <input autocomplete="family-name" id="footer-last-name" :aria-describedby="lastNameWithErrorId" v-model.trim="$v.lastName.$model"/>
      </div>
      <div class="footer-form-group" :class="{ 'form-group--error': $v.email.$error || freshAddressMessage.match(/not/) !== null || freshAddressMessage.match(/format/) !== null }">
        <label for="footer-email" v-html="emailWithError"></label>
        <input type="email" autocomplete="email" id="footer-email" :aria-describedby="emailWithErrorId" v-model.trim.lazy="$v.email.$model" @blur="blur"/>
      </div>
      <div class="footer-form-group" :class="{ 'form-group--error': $v.zip.$error }">
        <label for="footer-zip" v-html="zipWithError"></label>
        <input type="number" autocomplete="postal-code" id="footer-zip" :aria-describedby="zipWithErrorId" v-model.trim.lazy="$v.zip.$model"/>
      </div>

      <div class="footer-form-optins">
        <h3>Be a part of our community</h3>
        <p>We’ll send you conservation news and updates on how you can protect nature through activism, donating, events, and more. You can unsubscribe at any time.</p>
        <fieldset class="footer-form-group">
          <legend class="footer-optin-label" id="footer-email-optin-label">I would like to get (or continue to get) email updates:</legend>
          <div class="footer-optin-answers">
            <div class="footer-optin-answer" >
              <input id="footer-email-optin-yes" type="radio" class="footer-optin-answer-input" value="Y" name="footer-email-optin" v-model="emailOptin">
              <label for="footer-email-optin-yes" class="footer-optin-answer-label">Yes</label>
            </div>
            <div class="footer-optin-answer">
              <input id="footer-email-optin-no" type="radio" class="footer-optin-answer-input" value="N" name="footer-email-optin" v-model="emailOptin">
              <label for="footer-email-optin-no" class="footer-optin-answer-label">No</label>
            </div>
          </div>
        </fieldset>
        <fieldset class="footer-form-group">
          <legend class="footer-optin-label" id="footer-email-mobile-optin-label">I would like to get WWF text messages:</legend>
          <div class="footer-optin-answers">
            <div class="footer-optin-answer">
              <input id="footer-phone-optin-yes" type="radio" class="footer-optin-answer-input" value="Y" name="footer-phone-optin" @click="showPhoneInput" v-model="phoneOptin">
              <label for="footer-phone-optin-yes" class="footer-optin-answer-label">Yes</label>
            </div>
            <div class="footer-optin-answer">
              <input id="footer-phone-optin-no" type="radio" class="footer-optin-answer-input" value="N" name="footer-phone-optin" @click="hidePhoneInput" v-model="phoneOptin">
              <label for="footer-phone-optin-no" class="footer-optin-answer-label">No</label>
            </div>
          </div>
          <div class="footer-form-group" :class="{ 'form-group--error': $v.phone.$error }" v-if="phoneShowing">
            <label for="footer-phone" v-html="phoneWithError"></label>
            <input type="tel" autocomplete="tel-national" id="footer-phone" :aria-describedby="phoneWithErrorId" v-model.trim.lazy="$v.phone.$model"/>
            <p class="phone-disclaimer">By providing your mobile number, you agree to receive recurring text messages from WWF. Text STOP to quit, HELP for info. Message and data rates may apply.</p>
          </div>
        </fieldset>
      </div>

      <div v-if="enError" class="form-group--error">
        <p>There was an error submitting please try again or visit the <a href="https://protect.worldwildlife.org/page/50225/subscribe/1">signup page</a></p>
      </div>
      <div class="footer-flex-container footer-form-disclaimer">
        <button class="btn btn-simple footer-btn" v-if="!spinnerShowing">Sign Up</button>
        <LoadingSpinner v-else />
        <p>We will keep your information safe and secure. Please see our <a href="https://www.worldwildlife.org/pages/privacy-policy">Privacy Policy</a> for details of how we use your information.</p>
        <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        <div id="footer-recaptcha"></div>
      </div>
    </form>
  </section>
</template>

<script>
  import { required, requiredIf, minLength, maxLength, numeric, email, helpers } from 'vuelidate/lib/validators'
  import FooterEmailService from '../services/footerEmail'
  import GRecaptchaService from '../services/google_recaptcha'
  import LoadingSpinner from "./LoadingSpinner";

  const evalError = (params) => {
    if (params.required !== undefined && !params.required) {
      return "is required"
    }
    if (params.numeric !== undefined && !params.numeric) {
      return "should only be numbers"
    }
    if (params.minLength !== undefined &&
            params.maxLength !== undefined &&
            (!params.minLength || !params.maxLength)) {
      return "should be 5 digits"
    }
    if (params.email !== undefined && !params.email) {
      return "should be in this format: example@test.com"
    }
    if (params.phoneValidator !== undefined && !params.phoneValidator) {
      return "should be 10 digits"
    }
  }
  const phoneValidator = helpers.regex('number', /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)

  const handleFreshAddressResponse = response => {
    console.log(response)
    let formResult = {
      passedValidation: false // default false, prevent form submission
    };
    if (response.isServiceError()) {
      formResult.passedValidation = true; // Enable form submission
      return formResult; // Return custom response object.
    }
    /* CHECK RESULT: */
    if (response.isValid()) {
      /* Check if is suggestion available */
      if (response.hasSuggest()) {
        // Valid, with Suggestion: Provide opportunity for user to correct.
        formResult.suggestion = response.getSuggEmail();
      }
      else {
        // Valid, No Suggestion: Enable form submission.
        formResult.passedValidation = true;
      }
    }
    else if (response.isError() || response.isWarning()) {
      /* Check for Suggestion */
      if (response.hasSuggest()) {
        // Set response message. Provide opportunity for user to correct.
        formResult.suggestion = response.getSuggEmail();
      }
      else if (response.getFinding() === 'W') {
        // Set response message. Provide opportunity for user to correct.
        formResult.error = 'validity';
      }
      else if (response.getFinding() === 'E') {
        formResult.error = 'format';
      }
    }
    else {
      // Error Condition 2 - the service should always respond with finding E/W/V
      formResult.passedValidation = true;
    }
    return formResult
  }

  const updateFreshAddressMessage = (freshAddressResult, context) => {
    console.log(freshAddressResult)
    if (freshAddressResult.error !== undefined && freshAddressResult.error === 'validity') {
      context.freshAddressMessage = 'could not be validated'
      return false
    }
    if (freshAddressResult.error !== undefined && freshAddressResult.error === 'format') {
      context.freshAddressMessage = 'should be in this format: example@test.com'
      return false
    }
    if (freshAddressResult.suggestion !== undefined) {
      context.freshAddressMessage = `- Did you mean ${freshAddressResult.suggestion}?`
      context.email = freshAddressResult.suggestion
      return false
    }
    return true
  }

  const findQueryParam = (name) => {
    if(typeof(window.URLSearchParams)!="undefined"){
      const url = new URL(document.URL);
      const params = new URLSearchParams(url.search);
      return params.get(name) || '';
    }
    return nil
  }

  export default {
    mounted() {
      grecaptcha.ready(_ => {
        this.recaptchaWidget = GRecaptchaService.setup(this.captcha_callback)
      })
    },
    components: {
      LoadingSpinner
    },
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        zip: '',
        phone: '',
        emailOptin: null,
        phoneOptin: null,
        submitStatus: null,
        freshAddressMessage: '',
        spinnerShowing: false,
        phoneShowing: false,
        enError: false,
        recaptchaWidget: null,
        firstNameWithErrorId: false,
        lastNameWithErrorId: false,
        emailWithErrorId: false,
        zipWithErrorId: false,
        phoneWithErrorId: false
      }
    },
    validations: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      zip: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
        numeric
      },
      phone: {
        required: requiredIf('isPhoneOptin'),
        phoneValidator
      }
    },
    computed: {
      firstNameWithError() {
        let labelValue = "First Name <span class='footer-label-required'>(required)</span>";
        if (this.$v.firstName.$error) {
          this.firstNameWithErrorId = 'footer-firstName-error';
          return `<span role="alert" id="footer-firstName-error">${labelValue} ${evalError(this.$v.firstName)}</span>`;
        }
        this.firstNameWithErrorId = false;
        return labelValue;
      },
      lastNameWithError() {
        let labelValue = "Last Name <span class='footer-label-required'>(required)</span>";
        if (this.$v.lastName.$error) {
          this.lastNameWithErrorId = 'footer-lastName-error';
          return `<span role="alert" id="footer-lastName-error">${labelValue} ${evalError(this.$v.lastName)}</span>`;
        }
        this.lastNameWithErrorId = false;
        return labelValue;
      },
      emailWithError() {
        let labelValue = "Email Address <span class='footer-label-required'>(required)</span>";
        if (this.$v.email.$error) {
          this.emailWithErrorId = 'footer-email-error';
          return `<span role="alert" id="footer-email-error">${labelValue} ${evalError(this.$v.email)}</span>`;
        }
        this.emailWithErrorId = false;
        return labelValue;
      },
      zipWithError() {
        let labelValue = "Zip Code <span class='footer-label-required'>(required)</span>";
        if (this.$v.zip.$error) {
          this.zipWithErrorId = 'footer-zip-error';
          return `<span role="alert" id="footer-zip-error">${labelValue} ${evalError(this.$v.zip)}</span>`;
        }
        this.zipWithErrorId = false;
        return labelValue;
      },
      phoneWithError() {
        let labelValue = "Mobile Phone Number";
        if (this.$v.phone.$error) {
          this.phoneWithErrorId = 'footer-phone-error';
          return `<span role="alert" id="footer-phone-error">${labelValue} ${evalError(this.$v.phone)}</span>`;
        }
        this.phoneWithErrorId = false;
        return labelValue;
      },
      isPhoneOptin() {
        return this.phoneOptin && this.phoneOptin !== 'N';
      }
    },
    methods: {
      async submit() {
        console.log('submit!')
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
        } else {
          this.submitStatus = 'PENDING'
          this.freshAddressMessage = ''
          let valid;
          try {
            const freshAddressResponse = await FooterEmailService.getFreshAddress(this.email)
            const freshAddressResult = handleFreshAddressResponse(freshAddressResponse);
            valid = updateFreshAddressMessage(freshAddressResult, this);
          } catch (e) {
            console.log('error connecting to fresh address.')
            //bypassing freshaddress
            valid = true
          }
          if (valid) {
            // valid - submit to recaptcha
            GRecaptchaService.execute(this.recaptchaWidget)
          }
        }
      },
      async captcha_callback(token) {
        console.log(`in captcha_callback callback with token: ${token}`)
        this.showSpinner()
        let valid;
        try {
          const recaptchaResponse = await GRecaptchaService.verifyToken(token)
          console.log(recaptchaResponse)
          valid = recaptchaResponse.status && GRecaptchaService.isValid(recaptchaResponse.data.response)
          console.log(`after gcaptcha: ${valid}`)
        } catch (err) {
          //there was an error calling google recaptcha pass through
          console.error(err);
          valid = true
        }

        if (valid) {
          const result = await FooterEmailService.sendtoEngagingNetworks({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            zip: this.zip,
            phone: this.phone,
            emailOptIn: this.emailOptin,
            phoneOptIn: this.phoneOptin,
            opt_id: 8474,
            pageId: 50225,
            // originCategory: 'subscription',
            // originCode: 'Web_EmailSignup',
            enTrackingId: findQueryParam('ea.tracking.id') || 'Web_Footer',
            enAppealCode: findQueryParam('supporter.appealCode'),
            opt_in_questions:      [
              "OPT - Conservation Updates",
              "OPT - Fundraising",
              "OPT - Panda Nation",
              "OPT - Travel",
              "OPT - Activism",
              "OPT - WWFGifts"
            ]
          }, this.getAuthenticityToken())
          console.log(result)
          this.submitEmailCallback(result)
        } else {
          this.hideSpinner()
        }
      },
      submitEmailCallback(result) {
        console.log(result)
        if (result.status === 200 && result.data.status === 200) {
          window.location = 'https://protect.worldwildlife.org/page/50225/subscribe/2'
        } else {
          GRecaptchaService.reset(this.recaptchaWidget)
          this.hideSpinner()
          this.enError = true
        }
      },
      blur() {
        this.freshAddressMessage = ''
        this.$v.email.$touch()
        if (!this.$v.email.$invalid) {
          FooterEmailService.getFreshAddress(this.email)
                  .then(response => {
                    const freshAddressResult = handleFreshAddressResponse(response);
                    updateFreshAddressMessage(freshAddressResult, this)
                  })
        }
      },
      showSpinner() {
        this.spinnerShowing = true
      },
      hideSpinner() {
        this.spinnerShowing = false
      },
      showPhoneInput() {
        this.phoneShowing = true;
      },
      hidePhoneInput() {
        this.phoneShowing = false;
      },
      getAuthenticityToken() {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + "auth_token".replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const cookieValue = matches ? decodeURIComponent(matches[1]) : undefined;
        if (cookieValue !== undefined) {
          let cookieValueSplit = (cookieValue.split("--")[0]);
          return JSON.parse(atob(cookieValueSplit));
        }
       return cookieValue
      }
    }
}
</script>`

<style scoped>
  .email-signup-footer h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 8px;
  }
  .footer-form-optins {
    margin: 15px 0;
  }
  .footer-form-optins h3 {
    font-weight: bold;
    font-size: 16px;
  }
  .footer-form-optins p {
    margin-bottom: 8px;
  }
  .footer-form-group label {
    display: block;
    font-weight: bold;
  }
  .footer-form-group.form-group--error label, .form-group--error p {
    font-weight: bold;
  }
  .footer-form-group.form-group--error label span, .form-group--error p {
    text-decoration: underline;
  }
  .footer-form-group input {
    width: 100%;
  }
  .footer-form-group .phone-disclaimer {
    font-size: 14px;
  }
  .btn.footer-btn.hide {
    display: none;
  }
  .form-group--error input {
    border-color: #E7AEC6;
    outline-color: #E7AEC6;
    background-color: #E7AEC6;
  }
  .footer-form-disclaimer a {
    color: #fff;
    text-decoration: underline;
  }

  .footer-form-group .footer-optin-label {
    margin-bottom: 8px;
    font-weight: bold;
  }

  .footer-form-group .footer-optin-answers {
    display: flex;
    flex-direction: row;
  }
  .footer-form-group .footer-optin-answer {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5rem;
    padding: 4px 8px 8px 8px;
    font-size: 16px;
    align-items: center;
  }

  .footer-form-group .footer-optin-answer + .footer-form-group .footer-optin-answer {
    margin-top: 1em;
  }

  #footer .footer-form-group .footer-optin-answer-input {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  #footer .footer-form-group .footer-optin-answer-input:before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #000;
  }

  #footer .footer-form-group .footer-optin-answer-input:checked:before {
    transform: scale(1);
  }
</style>
<style>
  .grecaptcha-badge {
    visibility: hidden;
  }
  .footer-label-required {
    font-weight: normal;
  }
</style>
