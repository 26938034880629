import GRecaptchaService from './google_recaptcha'
import axios from "axios";

export default {
  async getFreshAddress(email) {
    return FreshAddress.validateEmail(email, {})
  },

  async sendtoEngagingNetworks(data, authenticity_token, callback) {
    return axios({
      method:   'post',
      dataType: 'json',
      url:      '/api/submit_email.json',
      params: {
        api_token: 'swimmingPandasInaPineTree',
      },
      data: data,
      headers: {
        "X-CSRF-Token": authenticity_token
      }
    })
  },
}
